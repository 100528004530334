import styled from 'styled-components';
import { colorUsage, fontWeight, getSpacing } from 'stylesheet';
import { Bako } from 'theme/icons';
export var HomeContainer = styled.div.withConfig({
  displayName: "Homestyle__HomeContainer",
  componentId: "sc-1abs0pw-0"
})(["display:flex;flex-direction:column;align-items:center;padding:", ";"], getSpacing(10));
export var Title = styled.h1.withConfig({
  displayName: "Homestyle__Title",
  componentId: "sc-1abs0pw-1"
})(["margin-bottom:", ";"], getSpacing(2));
export var LogoContainer = styled.div.withConfig({
  displayName: "Homestyle__LogoContainer",
  componentId: "sc-1abs0pw-2"
})(["display:flex;flex-direction:column;::after{text-align:center;content:'Il est pas beau ce logo ?';}"]);
export var Logo = styled(Bako).withConfig({
  displayName: "Homestyle__Logo",
  componentId: "sc-1abs0pw-3"
})(["@keyframes backgroundAnimation{0%{background-position:0% 50%;}50%{background-position:100% 50%;}100%{background-position:0% 50%;}}width:440px;height:440px;padding:", ";background:", ";background-size:200% 200%;border-radius:50%;animation:backgroundAnimation 5s ease infinite;fill:", ";"], getSpacing(4), colorUsage.buttonGradient, colorUsage.whiteIconColor);
export var Emphasis = styled.span.withConfig({
  displayName: "Homestyle__Emphasis",
  componentId: "sc-1abs0pw-4"
})(["font-weight:", ";"], fontWeight.bold);