var __jsx = React.createElement;
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Paper from 'components/common/Paper';
import { Emphasis, HomeContainer, Logo, LogoContainer, Title } from './Home.style';

var Home = function Home() {
  var _useState = useState(false),
      isLogoDisplayed = _useState[0],
      setLogoDisplayed = _useState[1];

  return __jsx(Paper, null, __jsx(HomeContainer, null, __jsx(Title, null, __jsx(FormattedMessage, {
    id: "home.welcome"
  }), __jsx(Emphasis, {
    onDoubleClick: function onDoubleClick() {
      return setLogoDisplayed(true);
    }
  }, "BAKO")), isLogoDisplayed && __jsx(LogoContainer, null, __jsx(Logo, null))));
};

export default Home;