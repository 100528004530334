import React from "react";
var __jsx = React.createElement;
import { FormattedMessage } from 'react-intl';
import Paper from 'components/common/Paper';
import { useSiteConfig } from 'libs/common/hooks';
import { NotAllowedContainer } from './NotAllowed.style';

var NotAllowed = function NotAllowed() {
  var _useSiteConfig = useSiteConfig(),
      siteLabel = _useSiteConfig.label;

  return __jsx(Paper, null, __jsx(NotAllowedContainer, null, __jsx(FormattedMessage, {
    id: "not-allowed.message",
    values: {
      siteLabel: siteLabel
    }
  })));
};

export default NotAllowed;